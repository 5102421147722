import React from 'react'
import { createContext, useState } from 'react'

const ActiveChipsNavigationContext = createContext()

function ChipsProvider({ children }) {
  const [activeElement, setActiveElement] = useState()

  const findActiveElement = (pageId) => {
    // such das Element
    // wenn Element gefunden
    setActiveElement(pageId)
  }

  const setActiveChip = (id, externId = null) => {
    // nav items extern sollten ursprünglich auch angezeigt werden
    setActiveElement(id)
  }

  const getActiveChip = () => {
    return activeElement || null
  }

  const valueToShare = {
    activeElement,
    findActiveElement,
    setActiveChip,
    getActiveChip,
  }

  return (
    <ActiveChipsNavigationContext.Provider value={valueToShare}>
      {children}
    </ActiveChipsNavigationContext.Provider>
  )
}

export { ChipsProvider }
export default ActiveChipsNavigationContext
