const React = require('react')
const { ChipsProvider } = require('./src/context/activeChipsNavigation')

require('./src/css/index.css')

exports.onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    require(`intersection-observer`)
    console.log(`# IntersectionObserver is polyfilled!`)
  }
}

exports.wrapRootElement = ({ element }) => {
  return (
    <ChipsProvider>
      <div id="top">{element}</div>
    </ChipsProvider>
  )
}

exports.onInitialClientRender = () => {
  // todo here checking if shoppingListItems are available or not and update items
}

exports.shouldUpdateScroll = ({ routerProps, prevRouterProps }) => {
  const currentLocation = routerProps.location?.search
  const prevLocation = prevRouterProps?.location?.search
  const sideBarPath = currentLocation || prevLocation
  if (
    sideBarPath === '?marktsuche=marktfinder' ||
    sideBarPath === '?einkaufsliste=einkaufszettel'
  ) {
    return false
  }
}

exports.onRouteUpdate = ({ location, prevLocation }) => {
  if (location && location.state)
    location.state.referrer = prevLocation ? prevLocation.pathname : null
}

//for future usage to inform users about site-updates

// exports.onServiceWorkerUpdateReady = () => {
//   // eslint-disable-next-line no-alert
//   const answer = window.confirm(
//     `Diese Anwendung wurde aktualisiert. ` +
//       `Möchten Sie neu laden, um die neueste Version anzuzeigen?`
//   )

//   if (answer) {
//     window.location.reload()
//   }
// }
